<template>
<teleport to="body">
    <div class="modal fade" id="modal-save-keyword" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-cs">
            <div class="modal-content modal-custom main-overlay-np">
                <div class="modal-header border-bottom-0">
                    <a type="button" class="close" data-bs-dismiss="modal" aria-label="Close" style="opacity: 1;">
                        <span class="input-search-close"></span>
                    </a>
                </div>
                <div class="modal-body">
                    <div class="box-wrapper-modal pt-1"  v-if="!saveKeywordSuccess">
                        <h2>Rename the search</h2>
                        <div class="box-input mb-3"><input type="text" class="form-control" placeholder="Write here" v-model="inputMySearch"></div>
                        <div><button class="btn-orange" style="width: 200px;" @click="saveMySearch">Enter</button></div>
                    </div>
                    <div style="text-align: center; display: block;" v-if="saveKeywordSuccess">
                        <div style=" padding: 0px 10px 20px 10px;font-size: 1.2em;">Your search has been saved successfully.</div>
                        <button class="btn-orange button200" data-bs-dismiss="modal" aria-label="Close" style="width: 200px;" >OK</button>
                    </div> 
                </div>
                <div v-if="loadingSaveKeyword" class="overlay">
                    <div class="loadding_circle"></div>
                </div>
            </div>
        </div>
    </div>
</teleport>
</template>

<script>
export default {
    name : "ModalSaveKeyword",
    props : {
        type: String,
    },
    data() {
        return {
            modal : null,
            saveKeywordSuccess : false,
            loadingSaveKeyword : false,
            inputMySearch : ""
        }
    },    
    methods: {
        setStart(){
            this.inputMySearch = "";
            this.saveKeywordSuccess = false;
        },
        saveMySearch(){
            if(this.inputMySearch === ""){
                alert('name field cannot be empty.');
                return false
            }            
            this.loadingSaveKeyword = true
            var keyword_decode = atob(this.$route.params.base64Search);
            var keyword_arr = keyword_decode.split("|");
            var keyword_json = {};

            var keyword_arr_value = [];
            for (var i = 0; i < keyword_arr.length; i++){
                if(keyword_arr[i] != ""){
                    keyword_arr_value[i] = keyword_arr[i].split("=");
                    keyword_json[keyword_arr_value[i][0]] = keyword_arr_value[i][1];
                }
            }
            this.$store.dispatch("users/saveKeyword", { name : this.inputMySearch, keyword : this.$route.params.base64Search, type : this.type, link : this.$route.path, keyword_decode : keyword_json}).then(()=> {
                this.saveKeywordSuccess = true
                this.loadingSaveKeyword = false
            });       
        },
    },
}
</script>

<style>

</style>