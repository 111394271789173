<template>
    <div class="loading_parent" v-if="isLoad" style="display: block">
        <div class="la-line-scale la-dark la-2x main-loading">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div class="load-overlay"></div>
    </div>
</template>

<script>
export default {
    name: 'Loader',
    props: ['isLoad'],
}
</script>
